import axiosAz from "axios";
import { Buffer } from "buffer";

async function uploadToAzureAPICall(dataUri, url) {
  try {
    delete axiosAz.defaults.headers.common["Authorization"];
    const response = await axiosAz.put(
      url,
      new Buffer.from(
        dataUri.replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      ),
      {
        headers: {
          "Content-Type": "image/png",
          "Content-Encoding": "base64",
          "x-ms-blob-type": "BlockBlob",
        },
      }
    );
    return response;
  } catch (error) {
    console.log("error", error);
    return error;
  }
}

export { uploadToAzureAPICall };
