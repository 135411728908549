import axios from "axios";

// sets the auth token in the header of all remaining calls to the api - authenitcating the user.
async function setAuthToken(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export default setAuthToken;
