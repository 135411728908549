import React, { useCallback, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CameraPhoto from 'jslib-html5-camera-photo';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Page from '../containers/Page';
import useSound from 'use-sound';

const videoRef = React.createRef();
let cameraPhoto = null;

function Home(props) {
  const [cameraId, setCameraId] = useState();
  const [devices, setDevices] = useState([]);
  const {
    client,
    isRecognized,
    isNotRecognized,
    ipAddress,
    successSound,
    failSound,
    loading,
  } = useStoreState((state) => state);

  const {
    setLoadingAction,
    setDataUriAction,
    submitRecogThunk,
    getCompanyCfgThunk,
  } = useStoreActions((actions) => actions);

  const sound = {
    url: 'https://kioskbranding.blob.core.windows.net/sounds/',
    config: {
      playbackRate: 0.5,
      volume: 1,
    },
  };
  const [playSuccessSound] = useSound(
    `${sound.url}${successSound || 'successBell'}.mp3`,
    sound.config
  );
  const [playFailSound] = useSound(
    `${sound.url}${failSound || 'fail'}.mp3`,
    sound.config
  );

  useEffect(() => {
    if (isRecognized && successSound) {
      playSuccessSound();
    } else if (isNotRecognized && failSound) {
      playFailSound();
    }
  }, [
    isRecognized,
    isNotRecognized,
    successSound,
    failSound,
    playSuccessSound,
    playFailSound,
  ]);

  const handleDevices = useCallback(
    (mediaDevices) => {
      const deviceList = mediaDevices.filter(
        ({ kind }) => kind === 'videoinput'
      );
      setDevices(deviceList);
      if (deviceList.length === 1) {
        setCameraId(deviceList[0].deviceId);
      }
    },
    [setDevices]
  );

  useEffect(() => {
    async function getDevices() {
      await navigator.mediaDevices.getUserMedia({ video: true });
      await navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }
    getDevices();
  }, [handleDevices]);

  useEffect(() => {
    getCompanyCfgThunk();
  }, [getCompanyCfgThunk]);

  const startCamera = useCallback(async () => {
    cameraPhoto = new CameraPhoto(videoRef.current);
    try {
      await cameraPhoto.startCamera(cameraId, { width: 640, height: 480 });
      console.log('Camera is started!');
    } catch (error) {
      console.error('Camera not started!', error);
    }
  }, [cameraId]);

  function takePhoto() {
    setLoadingAction(true);

    const cameraDataUri = cameraPhoto.getDataUri({
      sizeFactor: 1,
      imageCompression: 0.95,
    });

    setDataUriAction(cameraDataUri);

    setTimeout(
      () =>
        submitRecogThunk({
          client,
          dataUri: cameraDataUri,
          ipAddress,
        }),
      500
    );
  }

  useEffect(() => {
    if (cameraId) {
      localStorage.setItem('timeClockCameraId', cameraId);
      startCamera();
    }
  }, [cameraId, startCamera]);

  useEffect(() => {
    const cameraId = localStorage.getItem('timeClockCameraId');
    if (cameraId) {
      setCameraId(cameraId);
    }
  }, []);

  return (
    <Page
      className={`${isRecognized && 'alert-success'} ${
        isNotRecognized && 'alert-danger'
      }`}
      {...props}
    >
      <Container className='text-center'>
        <Row>
          <Col>
            <Form.Select
              aria-label='Default select example'
              value={cameraId || 'DEFAULT'}
              onChange={(e) => setCameraId(e.target.value)}
              style={{ maxWidth: '640px' }}
              className='mx-auto'
            >
              <option value='DEFAULT' disabled>
                Select Camera
              </option>
              {devices?.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <video
              style={{ width: '100%', maxHeight: '480px' }}
              ref={videoRef}
              autoPlay
              playsInline
            ></video>
          </Col>
        </Row>
        <button
          disabled={loading}
          id='camera--trigger'
          onClick={() => {
            if (!loading) {
              takePhoto();
            }
          }}
        >
          Clock-in / Clock-out
        </button>
      </Container>
    </Page>
  );
}

export default Home;
