import KioskClock from './KioskClock';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

function NavHeader({ logo }) {
  return (
    <Navbar
      fixed='top'
      className='bg-body-tertiary'
      style={{ borderBottom: '1px solid grey', background: 'white' }}
    >
      <Container fluid>
        <Navbar.Brand href='#home'>
          <img
            id='brandingLogo'
            src={`https://kioskbranding.blob.core.windows.net/images/${logo}`}
            style={{ width: '125px' }}
            alt=''
          />
        </Navbar.Brand>
        <Navbar.Collapse className='justify-content-end'>
          <Navbar.Text>
            <KioskClock />
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavHeader;
