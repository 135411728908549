import axios from "axios";

// CLOCKIN CALLS
async function loadCompanyMessageAPICall(client) {
  try {
    if (!client) {
      throw new Error("No Client Configuration Defined");
    }
    const res = await axios(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/clockin/${client}`
    );
    if (res && res.data) {
      return res;
    }
    throw new Error("Messages not retrieved");
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      error.message = error.response.data.error;
    }
    return error;
  }
}

async function postClockinAPICall(data) {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_DOMAIN}/cognitive/identify`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data,
    });
    if (res && res.data) {
      return res;
    }
    throw new Error("Unable to clockin");
  } catch (error) {
    return error;
  }
}

// CHOOCH CALLS
async function postChoochAPICall(data) {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_DOMAIN}/chooch/predict`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data,
    });
    if (res && res.status === 200) {
      return res;
    }
    throw new Error("Predict call failed");
  } catch (error) {
    return error;
  }
}

export { loadCompanyMessageAPICall, postClockinAPICall, postChoochAPICall };
