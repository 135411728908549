import Clock from 'react-live-clock';

function KioskClock(props) {
  return (
    <Clock
      style={{ fontSize: '28px', paddingRight: '8px' }}
      format='LTS'
      ticking={true}
      timezone='US/Central'
    />
  );
}

export default KioskClock;
