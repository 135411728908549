import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function Popup({ popupMessageHeader, popupMessage, alertColor, ...props }) {
  return (
    <Modal centered={true} {...props}>
      <ModalHeader className={`${alertColor}-backgroud`}>
        {popupMessageHeader}
      </ModalHeader>
      <ModalBody className="text-center">
        {popupMessage
          ? popupMessage
          : "We were not able to succesfully recognize your face."}
      </ModalBody>
      <ModalFooter className={`${alertColor}-backgroud`} />
    </Modal>
  );
}

export default Popup;
