import { useStoreState } from 'easy-peasy';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import NavHeader from '../presentational/Navbar';
import Popup from '../presentational/Popup';

function Page({ children, ...props }) {
  const {
    logo = 'clientsFirst.png',
    showPopup,
    popupMessage,
    popupMessageHeader,
    alertColor,
    loading,
  } = useStoreState((state) => state);

  return (
    <Container {...props} fluid style={{ height: '100vh' }}>
      {loading && (
        <div className='text-center' style={{ paddingBottom: '2rem' }}>
          <h5>Loading...</h5>
          <div>
            <Spinner animation='border' role='status'></Spinner>
          </div>
        </div>
      )}
      <Popup
        isOpen={showPopup}
        popupMessage={popupMessage}
        popupMessageHeader={popupMessageHeader}
        alertColor={alertColor}
      />
      <NavHeader logo={logo} />
      {children}
    </Container>
  );
}

export default Page;
