function About(props) {
  return (
    <div className="jumbotron text-center" {...props}>
      <h1>About</h1>
      <p>This is a bio-metric time-clock application for time entry</p>
      <p>Version 1.0.0</p>
    </div>
  );
}

export default About;
