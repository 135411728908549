const state = {
  client: "",
  ipAddress: "",
  appBaseUrl: "",
  cameraData: {
    dataUri: null,
    imgClass: "hidden",
  },
  txName: "",
  isRecognized: false,
  isNotRecognized: false,
  successSound: "",
  failSound: "",
};

export default state;
