import { action } from "easy-peasy";

const actions = {
  setLoadingAction: action((state, loading) => {
    state.loading = loading;
  }),
  setLogoAction: action((state, logo) => {
    state.logo = logo;
    document.getElementById(
      "brandingLogo"
    ).src = `https://kioskbranding.blob.core.windows.net/images/${logo}`;
  }),
  setAppIconAction: action((state, appIcon) => {
    state.appIcon = appIcon;
    document.getElementById(
      "appIcon"
    ).href = `https://kioskbranding.blob.core.windows.net/images/${appIcon}`;
  }),
  setFaviconAction: action((state, favicon) => {
    state.favicon = favicon;
    document.getElementById(
      "brandingFavicon"
    ).href = `https://kioskbranding.blob.core.windows.net/images/${favicon}`;
  }),
  setImgClassAction: action((state, imgClass) => {
    state.cameraData.imgClass = imgClass;
  }),
  setLogObjAction: action((state, logObj) => {
    state.logObj = logObj;
  }),
  createLogStepAction: action((state, step) => {
    const tx = state.txName;
    const txStep = step ? `${tx}-${step}` : tx;
    const log = state.logObj;

    if (log[txStep]) {
      function date(jsonProp) {
        return new Date(log[txStep][jsonProp]);
      }

      log[txStep]["stop"] = new Date().toISOString();
      log[txStep]["length"] = (date("stop") - date("start")) / 1000;
    } else {
      log[txStep] = { start: new Date(), stop: "" };
    }

    state.logObj = log;
  }),
};

export default actions;
