import { thunk } from "easy-peasy";
import setAuthToken from "../../utilities/token";
import { uploadToAzureAPICall } from "../../api/azure";
import { v4 } from "uuid";
import {
  loadCompanyMessageAPICall,
  postClockinAPICall,
} from "../../api/cfbsTimeClock";
import { createPostClockinPayload } from "./payloadBuilder";

const thunks = {
  getCompanyCfgThunk: thunk(async (actions) => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      let client = "";
      const tokenValue = queryParams.get("client");

      if (tokenValue) {
        localStorage.setItem("client", tokenValue);
        client = tokenValue;
      } else if (localStorage.getItem("client")) {
        client = localStorage.getItem("client");
      }
      await setAuthToken(client);

      actions.setLoadingAction(true);
      const res = await loadCompanyMessageAPICall(client);
      const {
        appBaseUrl,
        logo,
        favicon,
        confirmedClient,
        blobContainerUrl,
        blobContainerSAS,
        ipAddress,
        appIcon,
        successSound,
        failSound,
      } = res.data;

      res.data.confirmedClient.length > 0 &&
        localStorage.setItem("client", confirmedClient);
      actions.setClientAction(confirmedClient);

      actions.setIPAddressAction(ipAddress);
      actions.setAppBaseUrlAction(appBaseUrl);
      actions.setAppIconAction(appIcon);
      actions.setLogoAction(logo);
      actions.setFaviconAction(favicon);
      actions.setLoadingAction(false);
      actions.setBlobContainerUrlAction(blobContainerUrl);
      actions.setBlobContainerSASAction(blobContainerSAS);
      actions.setSuccessSoundAction(successSound);
      actions.setFailSoundAction(failSound);
    } catch (error) {
      let msg = "";
      typeof error === "object" ? (msg = error.message) : (msg = error);

      actions.processChoochResFailAction({
        message: msg,
        alertColor: "danger",
      });

      setTimeout(() => {
        actions.processCleanupAction({});
      }, 3000);

      actions.setLoadingAction(false);

      console.log("error", msg);
    }
  }),
  submitRecogThunk: thunk(
    async (actions, { client, dataUri, ipAddress }, helpers) => {
      const data = {
        client,
        dataUri,
        ipAddress,
        noBAccountID: true,
      };

      let txName = `TX${parseInt(Math.random() * 10000).toString()}`;
      actions.setTxNameAction(txName);
      actions.createLogStepAction("Frontend");

      try {
        actions.createLogStepAction("Uploadmage");

        const imageBaseUrl = `${
          helpers.getState().blobContainerUrl
        }${v4()}.png`;
        const sas = helpers.getState().blobContainerSAS;
        const url = `${imageBaseUrl}?${sas}`;
        data.fileUrl = imageBaseUrl;
        await uploadToAzureAPICall(dataUri, url);

        actions.createLogStepAction("Uploadmage");

        actions.createLogStepAction("PostClockin");

        data.logObj = helpers.getState().logObj;
        data.txName = helpers.getState().txName;

        // send to backend cognitive/identity
        const clockinPayload = await createPostClockinPayload(data);
        await setAuthToken(data.client);

        const res = await postClockinAPICall(clockinPayload);

        actions.setLogObjAction(res.data.logObj);

        res.data.ipAddress = data.ipAddress;
        if (res.data.recog) {
          actions.processChoochResSuccessAction(res.data.timeEntry);
        } else {
          actions.processChoochResFailAction(res.data);
        }
        actions.createLogStepAction("PostClockin");
        actions.createLogStepAction("Frontend");

        setTimeout(
          () => {
            const logObj = helpers.getState().logObj;
            actions.processCleanupAction(res.data);
            console.log(JSON.stringify(logObj, null, 2));
          },
          res.data.recog ? 2000 : 5000
        );
      } catch (err) {
        // debugger;
        console.log(err);
        err.response &&
          err.response.data &&
          err.response.data.logObj &&
          actions.setLogObjAction(err.response.data.logObj);
        console.log(JSON.stringify(helpers.getState().logObj, null, 2));
        actions.processChoochResFailAction({
          timeEntry: {
            Message: { value: "There was a problem recording your time entry" },
            MessageHeader: { value: "Retry Clock-in" },
          },
          alertColor: "danger",
        });

        setTimeout(() => {
          actions.processCleanupAction(
            err && err.response && err.response.data ? err.response.data : {}
          );
        }, 5000);
      }
    }
  ),
};

export default thunks;
