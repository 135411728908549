const state = {
  loading: true,
  alertColor: "primary",
  logo: "clientsFirst.png",
  favicon: "favicon.ico",
  showPopup: false,
  popupMessage: null,
  popupMessageHeader: null,
  logObj: {},
};

export default state;
