import { action } from "easy-peasy";

const actions = {
  setClientAction: action((state, client) => {
    state.client = client;
  }),
  setIPAddressAction: action((state, ip) => {
    state.ipAddress = ip;
  }),
  setDataUriAction: action((state, dataUri) => {
    state.cameraData.dataUri = dataUri;
  }),
  setAppBaseUrlAction: action((state, appBaseUrl) => {
    state.appBaseUrl = appBaseUrl;
  }),
  setBlobContainerUrlAction: action((state, blobContainerUrl) => {
    state.blobContainerUrl = blobContainerUrl;
  }),
  setBlobContainerSASAction: action((state, blobContainerSAS) => {
    state.blobContainerSAS = blobContainerSAS;
  }),
  setImgClassAction: action((state, imgClass) => {
    state.cameraData.imgClass = imgClass;
  }),
  processChoochResSuccessAction: action((state, data) => {
    state.isRecognized = true;
    state.isNotRecognized = false;
  }),
  processChoochResFailAction: action((state, data) => {
    state.isRecognized = false;
    state.isNotRecognized = true;
    state.loading = false;
    state.showPopup = true;
    state.popupMessage = data.message;
    state.alertColor = data.alertColor;
    state.popupMessageHeader = data.message
      ? "Attention!"
      : "Please see supervisor immediately";
  }),
  processCleanupAction: action((state, data) => {
    state.isRecognized = false;
    state.isNotRecognized = false;
    state.cameraData.dataUri = null;
    state.cameraData.imgClass = "hidden";
    state.loading = false;
    state.showPopup = false;
    state.popupMessage = null;
    state.popupMessageHeader = null;
    state.alertColor = "primary";
    state.txName = "";
    state.logObj = {};
  }),
  resetRecognitionAction: action((state, payload) => {
    state.isNotRecognized = false;
    state.isRecognized = false;
  }),
  setTxNameAction: action((state, txName) => {
    state.txName = txName;
  }),
  setSuccessSoundAction: action((state, sound) => {
    state.successSound = sound;
  }),
  setFailSoundAction: action((state, sound) => {
    state.failSound = sound;
  }),
};

export default actions;
